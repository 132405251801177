import React, { useState } from 'react'
import { CURRENCY_SYMBOL, PRESALE_ADDRESS, TOKEN_SYMBOL } from '../helper/constant';
import { CopyToClipboard } from "react-copy-to-clipboard";

export default function CopyAddressSection() {
    const [refcopy, setRefcopy] = useState(false);
    
    return (
        <section class="copyAddressSection" data-aos="fade-up">
            <p>You can buy {TOKEN_SYMBOL} also by sending {CURRENCY_SYMBOL} directly to Presale Contract Address</p>
            <div class="copyAddressWrapper">
                <p id="contract-address">{PRESALE_ADDRESS}</p>
                <CopyToClipboard text={PRESALE_ADDRESS} onCopy={() => {
                    setRefcopy(true);
                    setTimeout(() => {
                        setRefcopy(false);
                    }, 2000)
                }}>
                    {refcopy ? (
                        <i class="fa-solid fa-clipboard"></i>
                    ) : (
                        <i class="fa-solid fa-copy"></i>
                    )}

                </CopyToClipboard>
            </div>
        </section>
    )
}
