import React from 'react';
import b1Img from '../img/b1.png'
import b2Img from '../img/b2.png'
import b3Img from '../img/b3.png'
import b4Img from '../img/b4.png'

export default function BusinessPlanSection() {
    return (
        <section class="whitepaper" id="whitepaper" data-aos="fade-up">
            <div className='row d-flex align-items-start'>
                <div class="col-12 col-md-6 aos-init aos-animate" data-aos="fade-up">
                    <h3 class="aboutHeader text-purple" data-aos="fade-up">
                        KETAGOLD's Plant Investment Details
                    </h3>
                    <p className='mb-4' data-aos="fade-up">
                        Learn more about our project, team, and long-term strategy by exploring our comprehensive business plan and whitepaper. Gain a thorough understanding of our approach to gold processing investment, tokenomics, and plans for sustainable growth.
                    </p>
                </div>
                <div class="col-12 col-md-6 text-center aos-init aos-animate" data-aos="fade-up">
                    <div className='row' data-aos="fade-up">
                        <div className='col-6 mb-1 mb-md-4'>
                            <a target='_blank' rel="noreferrer" href='https://ketagold.com/docs/Business_ Plan_for_a_Gold_Processing_Plant_in_Tanzania.pdf'>
                                <img src={b1Img} className='mb-3 bussiness-img' alt='b1Img' />
                                <h4 className='text-white'>Business Plan</h4>
                            </a>
                        </div>
                        <div className='col-6 mb-1 mb-md-4'>
                            <a href='https://ketagold.com/docs/Whitepaper_KETAGOLD.pdf' target='_blank' rel="noreferrer">
                                <img src={b2Img} className='mb-3 bussiness-img' alt='b1Img' />
                                <h4 className='text-white'>Whitepaper</h4>
                            </a>
                        </div>
                        <div className='col-6 mt-4 mb-mt-4'>
                            <a href='https://ketagold.com/docs/VAT_LEACHING_GOLD_EXTRACTION.pdf' target='_blank' rel="noreferrer">
                                <img src={b3Img} className='mb-3 bussiness-img' alt='b1Img' />
                                <h4 className='text-white'>VAT LEACHING GOLD EXTRACTION</h4>
                            </a>
                        </div>
                        <div className='col-6 mt-4 mb-md-4'>
                            <a href='https://ketagold.com/docs/Annex-Financial-Estimates-Summary.pdf' target='_blank' rel="noreferrer">
                                <img src={b4Img} className='mb-3 bussiness-img' alt='b1Img' />
                                <h4 className='text-white'>Annex - Financial Estimates Summary</h4>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
