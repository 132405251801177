import React from 'react'
import team1Img from '../img/Kathleen-Davis.jpg';
import team2Img from '../img/James-Ochieng.jpg';
import team3Img from '../img/Sarah-Thompson.jpg';

export default function TeamSection() {
    return (
        <section class="teamSection" id="team">
            <h3 class="teamHeader aos-init aos-animate" data-aos="fade-up">Team</h3>
            <div class="teamBlock aos-init aos-animate" data-aos="fade-up">
                <div class="teamCard">
                    <img src={team1Img} alt="team-member" />
                    <h4 class="teamName">Dr. Kathleen Davis - Project Director</h4>
                    <p class="teamDes">Chief Executive Officer</p>
                </div>
                <div class="teamCard">
                    <img src={team2Img} alt="team-member" />
                    <h4 class="teamName">Mr. James Ochieng - Operations Manager</h4>
                    <p class="teamDes">Chief Operations Officer</p>
                </div>
                <div class="teamCard">
                    <img src={team3Img} alt="team-member" />
                    <h4 class="teamName">Ms. Sarah Thompson - Finance and Compliance Director</h4>
                    <p class="teamDes">Chief Financial Officer</p>
                </div>
            </div>
        </section>

    )
}
