import React from 'react'
import { useAccount } from 'wagmi';
import { useWeb3Modal } from "@web3modal/react";
import { trimAddress } from '../helper/functions';

export default function ConnectWallet({ className }) {
    const { open } = useWeb3Modal();
    const { address } = useAccount();

    return (
        address ? (
            <div className={className} onClick={open}>{trimAddress(address)}</div>
        ) : (
            <div className={className} onClick={open}>Connect Wallet</div>
        )

    )
}
