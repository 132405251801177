import { getMultiCall, getWeb3Contract } from '../helper/contractHelper';
import { PRESALE_ADDRESS, TOKEN_DECIMALS } from '../helper/constant';
import { useEffect, useState } from 'react';
import presaleAbi from '../abis/presale.json';
import { toast } from 'react-toastify';



export const useCommonStats = (updator) => {

    const [stats, setStats] = useState({
        saleStatus: false,
        totalPer: 0,
        privateSaleTokenLimit: 0,
        publicSaleTokenLimit: 0,
        endtime: 0,
        rate: 0,
        privateSaletotalusdraised: 0,
        publicSaletotalusdraised: 0,
        privateSaletotalTokensSold: 0,
        publicSaletotalTokensSold: 0,
        totalraisedAmount: 0,
        totalusdAmount : 0
    });

    let presaleContract = getWeb3Contract(presaleAbi, PRESALE_ADDRESS);
    useEffect(() => {
        const fetch = async () => {
            try {

                let data = await getMultiCall([
                    presaleContract.methods.saleStatus(), //0
                    presaleContract.methods.privateSaleTokenLimit(), //1
                    presaleContract.methods.publicSaleTokenLimit(), //2
                    presaleContract.methods.endtime(), //3
                    presaleContract.methods.publicrate(), //4
                    presaleContract.methods.privateSaletotalusdraised(), //5
                    presaleContract.methods.publicSaletotalusdraised(), //6
                    presaleContract.methods.privateSaletotalTokensSold(), //7
                    presaleContract.methods.publicSaletotalTokensSold(), //8

                ])


                setStats({
                    saleStatus: data[0],
                    privateSaleTokenLimit: data[1] / Math.pow(10, TOKEN_DECIMALS),
                    publicSaleTokenLimit: data[2] / Math.pow(10, TOKEN_DECIMALS),
                    endtime: data[3],
                    rate: data[4] / Math.pow(10, 18),
                    privateSaletotalusdraised: data[5] / Math.pow(10, 18),
                    publicSaletotalusdraised: data[6] / Math.pow(10, 18),
                    privateSaletotalTokensSold: data[7] / Math.pow(10, TOKEN_DECIMALS),
                    publicSaletotalTokensSold: data[8] / Math.pow(10, TOKEN_DECIMALS),
                    totalraisedAmount: (data[5] / Math.pow(10, 18)) + (data[6] / Math.pow(10, 18)),
                    totalusdAmount : ((data[1] / Math.pow(10, TOKEN_DECIMALS)) + (data[2] / Math.pow(10, TOKEN_DECIMALS))) * (data[4] / Math.pow(10, 18)),
                    totalPer: parseFloat(
                        (
                            ((data[7] / Math.pow(10, TOKEN_DECIMALS)) +
                                (data[8] / Math.pow(10, TOKEN_DECIMALS))) /
                            ((data[1] / Math.pow(10, TOKEN_DECIMALS)) +
                                (data[2] / Math.pow(10, TOKEN_DECIMALS)))
                        ) * 100
                    ).toFixed(2),
                });
            }
            catch (err) {
                console.log(err)
                toast.error(err.reason ? err.reason : err.message);
            }
        }


        fetch()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [updator])

    return stats;
}