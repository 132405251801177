import React, { useState } from 'react';
import { Accordion, AccordionSummary, AccordionDetails, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const faqData = [
    {
        question: "What is KETAGOLD (KETA)?",
        answer: "KETAGOLD (KETA) is a token associated with a gold processing plant project managed by GESMINE-INVEST Ltd. The project involves tokenizing shares of a gold processing plant in Tanzania to raise funds for the construction and operation of the plant, allowing investors to benefit from lucrative gold production. The tokens represent a stake in the plant's production, and investors can earn dividends from the gold produced."
    },
    {
        question: "How do I participate in the token sale?",
        answer: "To participate in the token sale, you can purchase KETA tokens with USDT (BEP-20) during the presale. The minimum purchase amount is 10,000 KETA tokens, and the maximum is limited to the remaining tokens available. The presale website provides a profit calculator and a staking module for investors to estimate potential earnings and manage their tokens."
    },
    {
        question: "When will I receive my dividends?",
        answer: "Provided that you have staked your tokens promptly after the soft cap fundraising, the distribution of dividends will commence three months after the completion of the soft cap fundraising. This initial period includes 60 days for the construction and equipping of the gold processing plant, and an additional 30 days for the first month of operation. After this period, dividends will be paid out every 32 days for a duration of one year."
    },
    {
        question: "How is the dividend calculated?",
        answer: "Dividends are calculated based on the monthly gold production, the price of gold, and the total number of tokens in circulation. The formula used is: Monthly revenue per token (in USDT)= (M * P * 0.5) / 1,000,000,000 where M is the total monthly gold production in kilograms, and P is the price of gold per kilogram. For example, if the monthly gold production is 4.5 kg and the gold price is $75,000 per kg, the monthly profit per token would be $0.00016875."
    },
    {
        question: "What happens after the 12-month dividend period?",
        answer: "You will be able to unstake your tokens and exchange them during our next token launch, which will fund another gold processing facility at a new gold mining site."
    },
    {
        question: "How can I sell my tokens if there is no liquidity transferred into the market?",
        answer: "If there is no liquidity, you cannot sell your tokens on secondary markets. However, you can sell your tokens back to us. We will buy them back if you change your mind, but only after the public sale has concluded. Please note that our tokens are not initially issued for trading but to receive dividends from gold production."
    },
    {
        question: "Is my investment guaranteed?",
        answer: "Yes, we will buy your tokens back if you change your mind, but only after the public sale has concluded."
    },
    {
        question: "How can I stake my tokens?",
        answer: `
            <p>To stake your tokens, follow these steps:</p>
            <ol type="1">
                <li>Ensure that the soft cap fundraising goal of 500,000 USDT has been reached.</li>
                <li>Use the platform to stake your tokens. Select the number of tokens you wish to stake for a staking period of approximately 15.8 months (474 days).</li>
                <li>Note that there is an initial lock-up period of 3 months during which you will not receive any revenue.</li>
                <li>After the 3-month lock-up period, monthly dividend payouts will begin and continue for approximately 12 months.</li>
                <li>Claim your USDT rewards approximately every 32 days for the duration of the staking period (384 days).</li>
                <li>You may unstake your tokens at any time. However, once unstaked, the action is irreversible, and those tokens cannot be restaked.</li>
            </ol>`

    },
    {
        question: "Can I sell my tokens before the staking period ends?",
        answer: "No, it’s not possible."
    },
    {
        question: "Can I sell my tokens after the staking period ends?",
        answer: "No, you can’t, but your investment has already yielded significant profits by the end of the staking period. Furthermore, you will have the opportunity to exchange your current tokens for new ones during our next token launch, which will fund another gold processing facility at a new gold mining site."
    },
    {
        question: "How are the winners in the lucky draw chosen?",
        answer: "The winners in the lucky draw are selected through a random drawing process. Each KETA token purchase during the presale acts as an entry into the draw, increasing the chances of being selected as a winner. Once the public sale ends, two winners are randomly chosen and awarded $10,000 in USDT each. The selection process is designed to be fair and unbiased, ensuring that every participant has an equal chance of winning. One token is equivalent to one lucky draw ticket."
    },
    {
        question: "Why is investing in KETAGOLD like owning stakes in multiple African gold mines?",
        answer: "Investing in KETAGOLD is like investing in multiple African gold mines simultaneously, thanks to our strategically located processing plant in Tanzania's rich goldfields. Our prime location, surrounded by numerous mines, allows us to process ore from neighboring sites and produce a substantial quantity of gold. Through tokenization, we offer a streamlined investment process, providing you with a share of our gold production without the intricacies of traditional mining ventures."
    }
    // Add more FAQ items here as needed
];

export default function FaqSection() {
    const [expanded, setExpanded] = useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };
    return (
        <section id="faq" class="calculator mt-5 mb-5">

            <div class="row justify-content-center">
                <div class="col-lg-10">
                    <h3 class="text-center mb-2 hero-title aos-init aos-animate">
                        Frequently Asked Questions
                    </h3>
                </div>
            </div>
            <div className='row mt-3 d-flex justify-content-center'>
                <div class="col-12 col-md-6 faq">
                    {faqData.map((faq, index) => (
                        <Accordion key={index}
                            expanded={expanded === index}
                            onChange={handleChange(index)}
                            className='accordion'>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon sx={{ color: 'white' }} />}
                                aria-controls={`panel${index}-content`}
                                id={`panel${index}-header`}
                                className='AccordionSummary'
                            >
                                <Typography style={{ fontSize: "17px" }}>{faq.question}</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography
                                    component="div"
                                    dangerouslySetInnerHTML={{ __html: faq.answer }}
                                />
                            </AccordionDetails>
                        </Accordion>
                    ))}
                </div>
            </div>
        </section>
    )
}
