import React from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import sliderImg1 from '../img/GoldMine1.jpg'
import sliderImg2 from '../img/GoldMine2.jpg'
import sliderImg3 from '../img/GoldMine3.jpg'
import sliderImg4 from '../img/GoldMine4.jpg'
import sliderImg5 from '../img/GoldMine5.jpg'
import sliderImg6 from '../img/GoldMine6.jpg'
import sliderImg7 from '../img/GoldMine7.jpg'
import sliderImg01 from '../img/Gold Minea.jpg'
import sliderImg02 from '../img/Gold Mineb.jpeg'
import sliderImg03 from '../img/Gold Minec.jpeg'
import sliderImg04 from '../img/Gold Mined.jpg'
import sliderImg05 from '../img/Gold Minee.jpeg'
import sliderImg06 from '../img/Gold Mineg.jpg'
import sliderImg07 from '../img/Gold Minei.jpg'



export default function Imageslideshow() {
    const images = [
        sliderImg01,
        sliderImg02,
        sliderImg03,
        sliderImg04,
        sliderImg05,
        sliderImg06,
        sliderImg07,
        sliderImg1,
        sliderImg2,
        sliderImg3,
        sliderImg4,
        sliderImg5,
        sliderImg6,
        sliderImg7
    ]

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 768, // Adjust for mobile
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    return (
        <Slider {...settings} className='mb-5'>
            {images.map((image, index) => (
                <div key={index} className="image-container">
                    <img src={image} alt={`slide-${index}`} />
                </div>
            ))}
        </Slider>
    )
}
