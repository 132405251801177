import React, { useEffect, useState } from 'react';
import Slider from '@mui/material/Slider';
import logoImg from '../img/logo.gif';
import claculatorImg from '../img/profit-calculator.jpg';
import { formatPrice } from '../helper/functions';


export default function CalculatorSection() {
    const [tokens, setTokens] = useState('');
    const [monthlyGoldProduction, setMonthlyGoldProduction] = useState(4.5);
    const [goldPrice, setGoldPrice] = useState(75000);
    const [monthlyReward,setMonthlyReward] = useState(0)
    const [annualReward,setAnnualReward] = useState(0)

    const handleSliderChange = (event, newValue) => {
        setGoldPrice(newValue);
    };

    const handleTokensChange = (event) => {
        setTokens(event.target.value);
    };

    const handleMonthlyGoldProductionChange = (event) => {
        setMonthlyGoldProduction(event.target.value);
    };

    useEffect(()=>{
        let totalreward = (tokens * monthlyGoldProduction * goldPrice * 0.5) / 1_000_000_000;
        setMonthlyReward(totalreward)
        setAnnualReward(totalreward * 12)
    },[tokens , goldPrice , monthlyGoldProduction])


    return (
        <section id="profit-calculator" className="calculator" data-aos="fade-up">
            <div className="row justify-content-center">
                <div className="col-lg-10">
                    <h3 className="text-center mb-2 hero-title aos-init aos-animate" data-aos="fade-up">
                        Calculate Your Potential Gold Mining Investment Returns
                    </h3>
                    <div className="row d-flex justify-content-center mb-5 aos-init aos-animate" data-aos="fade-up">
                        <div className="col-12 col-md-7">
                            <p className="text-center">
                                Use our profit calculator to estimate your potential returns based on the number of KETA tokens you hold, gold production, and current gold prices.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row mt-4 mt-lg-5 d-flex justify-content-center">
                <div className="col-12 col-md-5 mb-4 mb-md-0 text-center" data-aos="fade-up">
                    <img src={claculatorImg} alt="claculatorImg" className="top claculatorImg" />
                </div>
                <div className="col-lg-6 mb-5 order-1 order-lg-2" data-aos="fade-up">
                    <div className="box">
                        <h4 className="font-18 fw-bold mb-4">Returns Calculator</h4>
                        <div className="control-wrap">
                            <div className="d-flex align-items-center justify-content-between mb-2">
                                <label className="d-block family-title font-12">Number of KETA tokens</label>
                            </div>
                            <div className="amountField position-relative mb-3">
                                <input
                                    type="number"
                                    className="presaleInput"
                                    placeholder="Enter Number of KETA tokens"
                                    value={tokens}
                                    onChange={handleTokensChange}
                                />
                                <div className="amountType">
                                    <img src={logoImg} height="24px" width="24px" alt='logo' />
                                </div>
                            </div>
                            <div className="d-flex align-items-center justify-content-between mb-2">
                                <label className="d-block family-title font-12">Monthly gold production (kg)</label>
                            </div>
                            <div className="amountField position-relative mb-2">
                                <input
                                    type="number"
                                    className="presaleInput"
                                    placeholder="Enter Monthly gold production"
                                    value={monthlyGoldProduction}
                                    onChange={handleMonthlyGoldProductionChange}
                                />
                            </div>
                            <p className="font-12 text-start mb-1">Est. gold price per kg : ${goldPrice}</p>
                            <main>
                                <Slider
                                    value={goldPrice}
                                    onChange={handleSliderChange}
                                    defaultValue={75000}
                                    min={20000}
                                    max={150000}
                                    aria-label="Default"
                                    valueLabelDisplay="auto"
                                />
                            </main>
                            <div className='d-flex justify-content-between'>
                                <div>Estimated monthly dividend:</div>
                                <div>{formatPrice(monthlyReward)} USDT</div>
                            </div>
                            <div className='d-flex justify-content-between'>
                                <div>Estimated annual return:</div>
                                <div>{formatPrice(annualReward)} USDT</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}
