import React, { useEffect, useRef, useState } from 'react';
import featureImg1 from '../img/feature1.png';
import featureImg2 from '../img/feature2.png';
import featureImg3 from '../img/feature3.png';
import stakeImg from '../img/stake-img.png';
import { DEFAULT_CHAIN, DEFAULT_GAS_PRICE, STAKE_ADDRESS, TOKEN_ADDRESS, TOKEN_DECIMALS, TOKEN_NAME, TOKEN_SYMBOL } from '../helper/constant';
import axios from 'axios';
import { useStakeCommonStats, useStakeStats } from '../hooks/useAccount';
import { formatDate, formatPrice, getWeb3 } from '../helper/functions';
import { getContract, getEstimatedReward } from '../helper/contractHelper';
import { useAccount, useNetwork } from 'wagmi';
import tokenAbi from '../abis/token.json';
import stakeAbi from '../abis/stake.json';
import { toast } from 'react-toastify';
import { ethers } from 'ethers';
import ConnectWallet from './ConnectWallet';
import logoImg from '../img/logo.gif';
import { useEthersSigner } from '../hooks/useEthersProvider';



export default function StakingSection() {
    const [amount, setAmount] = useState('')
    const [tokenAmount, setTokenAmount] = useState(0);
    const [updator, setUpdator] = useState(1);
    const stakeStats = useStakeStats(updator);
    const stats = useStakeCommonStats(updator);
    const [goldprice, setGoldPrice] = useState(0);
    const timeoutRef = useRef(null);
    const { address } = useAccount();
    const { chain } = useNetwork();
    const signer = useEthersSigner();
    const [loading, setLoading] = useState(false);
    const [cloading, setCLoading] = useState(false);

    useEffect(() => {
        async function fetch() {
            let response = await axios.get('https://api.gold-api.com/price/XAU');
            if (response && response.data) {

                setGoldPrice(parseFloat(response.data.price) * 32.1507)
            }
        }

        fetch()
    }, [])


    const handleChangeAmount = async (value) => {
        const patt = /^\d+\.{0,1}\d{0,6}$/;
        if (value === '' || value === '0') {
            setAmount(value);
            setTokenAmount(0)
        }
        else if (patt.test(value)) {
            setAmount(value);
        }

        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
        }


        timeoutRef.current = setTimeout(async () => {
            if (patt.test(value)) {
                let resAmount = await getEstimatedReward(value);
                console.log(resAmount);
                setTokenAmount(resAmount)
            }
        }, 1000);

    };

    const handleApprove = async () => {
        if (address) {
            if (parseInt(chain.id) === parseInt(DEFAULT_CHAIN)) {
                try {

                    setLoading(true);
                    let amount1 = ethers.utils.parseUnits(amount.toString(), TOKEN_DECIMALS);
                    let tokenContract = getContract(tokenAbi, TOKEN_ADDRESS, signer)
                    const tx = await tokenContract.approve(
                        STAKE_ADDRESS,
                        amount1, 
                        { 'from': address, gasPrice: ethers.utils.parseUnits(DEFAULT_GAS_PRICE, 'gwei') });

                    toast.loading('Waiting for confirmation')

                    var interval = setInterval(async function () {
                        let web3 = getWeb3();
                        var response = await web3.eth.getTransactionReceipt(tx.hash);
                        if (response != null) {
                            clearInterval(interval)
                            if (response.status === true) {
                                toast.dismiss()
                                toast.success('success ! your last transaction is success 👍');
                                setLoading(false);
                                setUpdator(Math.random());

                            }
                            else if (response.status === false) {
                                toast.dismiss()
                                toast.error('error ! Your last transaction is failed.');
                                setLoading(false);
                                setUpdator(Math.random());

                            }
                            else {
                                toast.dismiss()
                                toast.error('error ! something went wrong.');
                                setLoading(false);
                                setUpdator(Math.random());

                            }
                        }
                    }, 5000);
                }
                catch (error) {
                    console.log(error.message)
                    toast.dismiss()
                    toast.error(error.reason ? error.reason : error.message);
                    setLoading(false);

                }
            }
            else {
                toast.dismiss()
                toast.error('Please select Binance Mainnet Network !');
                setLoading(false);

            }

        }
        else {
            toast.dismiss()
            toast.error('Please Connect Wallet!');
            setLoading(false);

        }
    }

    const handleSubmit = async () => {
        if (address) {
            if (parseInt(chain.id) === parseInt(DEFAULT_CHAIN)) {
                try {

                    setLoading(true);
                    let amount1 = ethers.utils.parseUnits(amount.toString(), TOKEN_DECIMALS);
                    let presaleContract = getContract(stakeAbi, STAKE_ADDRESS, signer)
                    const tx = await presaleContract.deposit(
                        amount1,
                        { 'from': address, gasPrice: ethers.utils.parseUnits(DEFAULT_GAS_PRICE, 'gwei') });
                    
                    toast.loading('Waiting for confirmation')

                    var interval = setInterval(async function () {
                        let web3 = getWeb3();
                        var response = await web3.eth.getTransactionReceipt(tx.hash);
                        if (response != null) {
                            clearInterval(interval)
                            if (response.status === true) {
                                toast.dismiss()
                                toast.success('success ! your last transaction is success 👍');
                                setLoading(false);
                                setUpdator(Math.random());

                            }
                            else if (response.status === false) {
                                toast.dismiss()
                                toast.error('error ! Your last transaction is failed.');
                                setLoading(false);
                                setUpdator(Math.random());

                            }
                            else {
                                toast.dismiss()
                                toast.error('error ! something went wrong.');
                                setLoading(false);
                                setUpdator(Math.random());

                            }
                        }
                    }, 5000);
                }
                catch (error) {
                    console.log(error.message)
                    toast.dismiss()
                    toast.error(error.reason ? error.reason : error.message);
                    setLoading(false);

                }
            }
            else {
                toast.dismiss()
                toast.error('Please select Binance Mainnet Network !');
                setLoading(false);

            }

        }
        else {
            toast.dismiss()
            toast.error('Please Connect Wallet!');
            setLoading(false);

        }
    }

    const handleClaimReward = async () => {
        if (address) {
            if (parseInt(chain.id) === parseInt(DEFAULT_CHAIN)) {
                try {

                    setCLoading(true);
                    let presaleContract = getContract(stakeAbi, STAKE_ADDRESS, signer)
                    const tx = await presaleContract.claim({ 'from': address, gasPrice: ethers.utils.parseUnits(DEFAULT_GAS_PRICE, 'gwei') });
                    toast.loading('Waiting for confirmation')

                    var interval = setInterval(async function () {
                        let web3 = getWeb3();
                        var response = await web3.eth.getTransactionReceipt(tx.hash);
                        if (response != null) {
                            clearInterval(interval)
                            if (response.status === true) {
                                toast.dismiss()
                                toast.success('success ! your last transaction is success 👍');
                                setCLoading(false);
                                setUpdator(Math.random());

                            }
                            else if (response.status === false) {
                                toast.dismiss()
                                toast.error('error ! Your last transaction is failed.');
                                setCLoading(false);
                                setUpdator(Math.random());

                            }
                            else {
                                toast.dismiss()
                                toast.error('error ! something went wrong.');
                                setCLoading(false);
                                setUpdator(Math.random());

                            }
                        }
                    }, 5000);
                }
                catch (error) {
                    console.log(error.message)
                    toast.dismiss()
                    toast.error(error.reason ? error.reason : error.message);
                    setCLoading(false);

                }
            }
            else {
                toast.dismiss()
                toast.error('Please select Binance Mainnet Network !');
                setCLoading(false);

            }

        }
        else {
            toast.dismiss()
            toast.error('Please Connect Wallet!');
            setCLoading(false);

        }
    }

    const handleUnstake = async () => {
        if (address) {
            if (parseInt(chain.id) === parseInt(DEFAULT_CHAIN)) {
                try {
                    let presaleContract = getContract(stakeAbi, STAKE_ADDRESS, signer)
                    setLoading(true);
                    
                    const tx = await presaleContract.withdraw({ 'from': address, gasPrice: ethers.utils.parseUnits(DEFAULT_GAS_PRICE, 'gwei') });
                    toast.loading('Waiting for confirmation')

                    var interval = setInterval(async function () {
                        let web3 = getWeb3();
                        var response = await web3.eth.getTransactionReceipt(tx.hash);
                        if (response != null) {
                            clearInterval(interval)
                            if (response.status === true) {
                                toast.dismiss()
                                toast.success('success ! your last transaction is success 👍');
                                setLoading(false);
                                setUpdator(Math.random());

                            }
                            else if (response.status === false) {
                                toast.dismiss()
                                toast.error('error ! Your last transaction is failed.');
                                setLoading(false);
                                setUpdator(Math.random());

                            }
                            else {
                                toast.dismiss()
                                toast.error('error ! something went wrong.');
                                setLoading(false);
                                setUpdator(Math.random());

                            }
                        }
                    }, 5000);
                }
                catch (error) {
                    console.log(error.message)
                    toast.dismiss()
                    toast.error(error.reason ? error.reason : error.message);
                    setLoading(false);

                }
            }
            else {
                toast.dismiss()
                toast.error('Please select Binance Mainnet Network !');
                setLoading(false);

            }

        }
        else {
            toast.dismiss()
            toast.error('Please Connect Wallet!');
            setLoading(false);

        }
    }

    const STAKE_TIME = parseInt((parseFloat(stats.NO_REWARD_PERIOD) + parseFloat(stats.STAKING_PERIOD)))



    return (
        <section class="featureSection" id="staking" data-aos="fade-up">
            <h3 className='text-center mb-2 hero-title' data-aos="fade-up">Stake KETA Tokens and Earn Gold Dividends</h3>
            <div className='row d-flex justify-content-center mb-5' data-aos="fade-up">
                <div className='col-12 col-md-7'>
                    <p className='text-center'>Staking your KETA tokens allows you to earn monthly dividends based on the plant's gold production. After a 3-month lock period, you'll receive USDT dividends for 12 months, directly linked to the plant's production output.</p>
                </div>
            </div>
            <div class="featureBlock aos-init aos-animate row" data-aos="fade-up">
                <div class="featureCard col-12 col-md-4">
                    <img src={featureImg1} alt='featureImg1' className='mb-3 stake-img' data-aos="fade-up" />
                    <h4 class="featureHead" data-aos="fade-up">Purchase KETAGOLD</h4>
                    <p class="featureText" data-aos="fade-up">Buy KETA tokens during the presale using USDT (BEP-20). The minimum purchase is 10,000 KETA tokens, with prices ranging from $$0.0009 to $0.00105 per token.</p>
                </div>
                <div class="featureCard col-12 col-md-4">
                    <img src={featureImg2} alt='featureImg2' className='mb-3 stake-img' data-aos="fade-up" />
                    <h4 class="featureHead" data-aos="fade-up">Stake your Tokens for 15 months</h4>
                    <p class="featureText" data-aos="fade-up">Once the soft cap of 500,000 USDT is reached, stake your KETA tokens on the platform. There's a 3-month lock period during which no rewards are earned. The total staking period is 15 months.</p>
                </div>
                <div class="featureCard col-12 col-md-4">
                    <img src={featureImg3} alt='featureImg3' className='mb-3 stake-img' data-aos="fade-up" />
                    <h4 class="featureHead" data-aos="fade-up">Earn Dividends</h4>
                    <p class="featureText" data-aos="fade-up">After the initial 3-month lock, receive monthly USDT dividends for 12 months. Rewards are based on the plant's production, with 50% of gold revenue distributed to token holders. Earnings are calculated using the formula:
                        Monthly revenue per token = (Monthly gold production * Gold price * 0.5) / 1,000,000,000.</p>
                </div>
            </div>
            <div className='row mt-5 d-flex align-items-center'>
                <div className='col-12 col-md-6 mb-1 mb-md-0'>
                    <img src={stakeImg} className='distribution-img' alt='stake-img' />
                </div>
                <div className='col-12 col-md-6 mt-4 mb-mt-0'>
                    <div class="p-3 d-flex flex-column gap-2 stake-out-box" data-test="staking_widget">
                        <h3 class="font-18 fw-bold mb-2 text-purple text-center">{TOKEN_NAME} Staking</h3>

                        <div class="row d-flex justify-content-around align-items-center">
                            <div className='col-12'>


                                {stakeStats.purchasedOf > 0 ? (
                                    <>
                                        <div class="token-card">
                                            <div class="token-info">
                                                <img src={logoImg} height="30px" alt='logo' />
                                                <span class="token-name">{TOKEN_SYMBOL}</span>
                                            </div>
                                            <div class="token-balance">{formatPrice(stakeStats.purchasedOf)}</div>
                                        </div>

                                    </>
                                ) : (
                                    <div class="rounded card-border d-flex flex-column gap-3 p-3">
                                        <div class="d-flex align-items-center justify-content-between">
                                            <span class="h6  subtitle-2" data-test="staking_widget_slider_amount">
                                                Stake <span class="text-bold">{amount ? amount : 0}</span> {TOKEN_SYMBOL}
                                            </span>
                                            <div class="d-flex align-items-center justify-content-center gap-2">
                                                {stats.stakestatus ? (
                                                    <div class="d-inline-block text-nowrap rounded-pill px-2 py-1  bg-success font-monospace fs-10">
                                                        Active
                                                    </div>
                                                ) : (
                                                    <div class="d-inline-block text-nowrap rounded-pill px-2 py-1  bg-danfer font-monospace fs-10">
                                                        InActive
                                                    </div>
                                                )}

                                            </div>
                                        </div>
                                        <input value={amount} onChange={(e) => handleChangeAmount(e.target.value)} type="text" class="presaleInput" placeholder="Enter Stake Amount" />
                                        <div class="d-flex w-100 align-items-center gap-3">
                                            <div class="d-flex w-100 gap-2 flex-md-column">
                                                <p class="subtitle-3 text-gray-700">
                                                    <span>Available</span>
                                                    <span class="text-gray-900" data-test="staking_widget_available_balance">
                                                        {" "}{stakeStats.tokenBal ? formatPrice(stakeStats.tokenBal, 14) : 0} {TOKEN_SYMBOL}
                                                    </span>
                                                </p>
                                            </div>
                                            <button onClick={() => handleChangeAmount(parseInt(stakeStats.tokenBal))} type="button" class="connect-btn gap-2 text-nowrap btn-max">
                                                Stake max
                                            </button>
                                        </div>
                                    </div>
                                )}


                            </div>
                            {stakeStats.purchasedOf === 0 &&
                                <div className='col-12 col-md-11 d-flex justify-content-between px-3 mt-2'>
                                    <div class="h6">Staking Period</div>
                                    <div class="h6 text-purple">{parseInt(STAKE_TIME / 86400)} Days</div>
                                </div>
                            }
                            <div className='col-12 col-md-11 d-flex justify-content-between px-3 mt-2'>
                                <div class="h6">Gold production</div>
                                <div class="h6 text-purple">{parseFloat(stats.goldproduction / 100)} KG</div>
                            </div>
                            <div className='col-12 col-md-11 d-flex justify-content-between px-3 mt-2'>
                                <div class="h6">Gold price (kg)</div>
                                <div class="h6 text-purple"> ${formatPrice(parseFloat(goldprice))}</div>
                            </div>
                            {stakeStats.purchasedOf > 0 ? (
                                <>
                                    <div className='col-12 col-md-11 d-flex justify-content-between px-3 mt-2'>
                                        <div class="h6">Pending Reward</div>
                                        <div class="h6 text-purple">{formatPrice(stakeStats.pendingDividend)} USDT</div>
                                    </div>
                                    <div className='col-12 col-md-11 d-flex justify-content-between px-3 mt-2 mb-2'>
                                        <div class="h6">Unlock Date</div>
                                        <div class="h6 text-purple">{formatDate(parseInt(stakeStats.unlocktime) * parseInt(1000))}</div>
                                    </div>
                                </>
                            ) : (
                                <>
                                    <div className='col-12 col-md-11 d-flex justify-content-between px-3 mt-2'>
                                        <div class="h6">Estimated Reward</div>
                                        <div class="h6 text-purple">{formatPrice(tokenAmount)} USDT</div>
                                    </div>
                                    <div className='col-12 col-md-11 d-flex justify-content-between px-3 mt-2 mb-2'>
                                        <div class="h6">Unstake Date</div>
                                        <div class="h6 text-purple">{stats.stakestatus ? formatDate(Math.floor(new Date().getTime()) + parseInt(STAKE_TIME * 1000)) : ' - '}</div>
                                    </div>
                                </>
                            )}

                            <div className='col-12 col-md-6 mt-3 d-flex justify-content-center'>

                                {address ? (
                                    stakeStats.purchasedOf > 0 ? (
                                        <>
                                            <div className='mx-2'>
                                                <button onClick={() => handleClaimReward()} type="button" disabled={cloading || parseFloat(stakeStats.pendingDividend) <= 0} className="presaleActionBtn" style={{ width: "auto" }}>
                                                    {cloading ? 'Loading...' : 'CLaim Reward'}
                                                </button>
                                            </div>
                                            <div className='mx-2'>

                                                <button onClick={() => handleUnstake()} type="button" disabled={loading || stakeStats.isClaimed || Math.floor(new Date().getTime() / 1000.0) <= parseFloat(stakeStats.unlocktime)} className="presaleActionBtn" style={{ width: "auto" }}>
                                                    {loading ? 'Loading...' : 'Unstake Now'}
                                                </button>
                                            </div>
                                        </>

                                    ) : (
                                        parseFloat(stakeStats.isApproved) >= amount ? (
                                            <button onClick={() => handleSubmit()} type="button" disabled={loading || !stats.stakestatus} className="presaleActionBtn">
                                                {loading ? 'Loading...' : 'Stake Now'}
                                            </button>
                                        ) : (
                                            <button onClick={() => handleApprove()} type="button" disabled={loading || !stats.stakestatus} className="presaleActionBtn">
                                                {loading ? 'Loading...' : 'Approve'}
                                            </button>
                                        )
                                    )) : (
                                    <ConnectWallet className="presaleActionBtn" />
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section >
    )
}
