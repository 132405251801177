import React from 'react';
import LuckyDrawImg from '../img/luckydraw.gif'

export default function LuckydrawSection() {
    return (
        <section class="aboutSection" id="lucky-draw" data-aos="fade-up">
            <div class="aboutTextSection aos-init aos-animate" data-aos="fade-up">
                <h3 class="aboutHeader" data-aos="fade-up">KETA Presale Lucky Draw - Win $10,000!</h3>
                <p className='mb-4' data-aos="fade-up">As part of our presale, we're giving away $10,000 to two lucky investors! Each winner will receive $10,000 in USDT. Every KETA token purchased during the presale automatically enters you into the draw. One is equivalent to one lucky draw ticket.</p>
                <p className='mb-2' data-aos="fade-up">How to Participate:</p>
                <div class="aboutText mb-2" data-aos="fade-up">
                    1. Purchase KETA tokens during the private or public sale.
                </div>
                <div class="aboutText mb-2" data-aos="fade-up">
                    2. Automatically entered for every token purchased.
                </div>
                <div class="aboutText mb-2" data-aos="fade-up">
                    3. Two winners selected randomly after public sale ends.
                </div>
            </div>
            <div class="aboutImageSection aos-init aos-animate" data-aos="fade-up">
                <img src={LuckyDrawImg} alt="luckimage" />
            </div>
        </section>
    )
}
