import Web3 from "web3";
import { DEFAULT_RPC } from "./constant";
import moment from "moment";


export const trimAddress = (addr , length = 6) => {
  return `${addr.substring(0, length)}...${addr.substring(addr.length - length)}`;
}


export const getWeb3 = () => {
  return new Web3(DEFAULT_RPC);
}

export const formatDateWithZone = (unixTime) => {
  try {
    let dateString = new Date(`${unixTime} UTC`).toString();
    let startIndex = dateString.indexOf("GMT");
    let modifyDate = dateString.substring(0, startIndex);
    return modifyDate;
  }
  catch (err) {
    console.log(err.message);
  }

}

export const formatDate = (uinxTime) => {
  return moment(uinxTime).format('MMMM Do YYYY, h:mm');
}


export const formatPrice = (num, decimals = 8) => {
  if (num >= 1) {
    return new Intl.NumberFormat('en-US', { maximumSignificantDigits: decimals }).format(num);
  }
  else {
    return new Intl.NumberFormat('en-US', { maximumSignificantDigits: 4 }).format(num);
  }
}


export const getNextVestingTime = (tgeDate, cycle) => {
  let nextVestingTime = parseInt(tgeDate);
  const now = Math.floor(new Date().getTime()/1000.0);

  if (nextVestingTime > now) {
    return nextVestingTime;
  }

  while (nextVestingTime <= now) {
    nextVestingTime += parseInt(cycle);
  }
  
  return nextVestingTime;
};