import React from 'react'
import XIcon from '@mui/icons-material/X';

export default function ContactSection() {
    return (
        <section class="contactSection" id="contact">
            <h3 class="contactHeader aos-init aos-animate" data-aos="fade-up">Contact Us</h3>
            <div class="contactList aos-init aos-animate" data-aos="fade-up">
                <a href="mailto:info@ketagold.com">
                    <i class="fa-solid fa-envelope"></i>info@ketagold.com
                </a>
                <a target='_blank' rel="noreferrer" href="https://ketagold.com/info/X"><XIcon sx={{ color: "#ffb43c" }} fontSize="small" />Twitter (X)</a>
                <a target='_blank' rel="noreferrer" href="https://ketagold.com/info/Telegram"><i class="fa-brands fa-telegram"></i> Telegram</a>
                <a target='_blank' rel="noreferrer" href="https://ketagold.com/info/LinkedIn"><i class="fa-brands fa-linkedin"></i>LinkedIn</a>
                <a target='_blank' rel="noreferrer" href="https://ketagold.com/info/Discord"><i class="fa-brands fa-discord"></i> Discord</a>
            </div>
        </section>

    )
}
