import React, {useState } from 'react';
import { useAccount, useNetwork } from 'wagmi';
import presaleABI from '../abis/presale.json';
import stakeABI from '../abis/stake.json';
import { DEFAULT_CHAIN, PRESALE_ADDRESS, STAKE_ADDRESS, TOKEN_DECIMALS } from '../helper/constant';
import logoImg from '../img/logo.gif';
import { toast } from 'react-toastify';
import { getWeb3 } from '../helper/functions';
import { ethers } from 'ethers';
import ConnectWallet from '../components/ConnectWallet';
import { Link } from 'react-router-dom';
import { getContract } from '../helper/contractHelper';
import { useEthersSigner } from '../hooks/useEthersProvider';



export default function Admin() {
    const { address } = useAccount();
    const { chain } = useNetwork();
    const signer = useEthersSigner();
    const [inputs, setInputs] = useState({
        token_address: "",       // For rescueToken
        finish_timestamp: "",    // For setEndTime
        min_purchase_limit: "",  // For setMinPurchaseLimit
        public_sale_price: "",          // For setRate
        private_sale_price: "",          // For setRate
        sale_status: "true",         // For setSaleStatus
        sale_type: "",           // For setSaleType
        treasurywallet: "",           // For setSaleType
        ketatoken : "",
        ketatokendecimals : "",

        no_reward_period: "",      // For setNoRewardPeriod
        gold_production: "",       // For setParameters
        gold_price: "",            // For setParameters
        reward_interval: "",       // For setRewardInterval
        staking_period: "",        // For setStakingPeriod
        staking_status: "true",        // For toggleStaking
        claim_status: "true",          // For toggleClaimStatus
        withdraw_status: "true",       // For toggleWithdrawStatus
        stake_token_address: "",         // For withdrawTokens
        token_amount: "",          // For withdrawTokens
        token_decimals: "",          // For withdrawTokens
    });
    const [updater, setUpdater] = useState(0);

    const handleChange = (e) => {
        setInputs({
            ...inputs,
            [e.target.name]: e.target.value
        });
    };

    const handleSubmit = async (id = 0) => {
        if (address && id) {
            if (chain && chain.id && parseInt(chain.id) === parseInt(DEFAULT_CHAIN)) {
                try {
                    let presaleContract = getContract(presaleABI , PRESALE_ADDRESS , signer);
                    let tx;
                    if (id === 1) {
                        tx = await presaleContract.rescueToken(inputs.token_address, { from: address });
                    } else if (id === 2) {
                        tx = await presaleContract.setEndTime(inputs.finish_timestamp, { from: address });
                    } else if (id === 3) {
                        let a = ethers.utils.parseUnits(inputs.min_purchase_limit.toString(), TOKEN_DECIMALS);
                        tx = await presaleContract.setMinPurchaseLimit(a, { from: address });
                    } else if (id === 4) {
                        let a = ethers.utils.parseUnits([inputs.private_sale_price.toString(), 18]);
                        tx = await presaleContract.setPrivateRate(a, { from: address });
                    } else if (id === 5) {
                        const saleStatus = inputs.sale_status.toLowerCase() === 'true';
                        tx = await presaleContract.setSaleStatus(saleStatus, { from: address });
                    } else if (id === 6) {
                        const saleType = parseInt(inputs.sale_type, 10); // 0 for private, 1 for public
                        tx = await presaleContract.setSaleType(saleType, { from: address });
                    } else if (id === 7) {
                        tx = await presaleContract.withdrawAllSaleTokens({ from: address });
                    } else if (id === 8) {
                        tx = await presaleContract.rescueBnb({ from: address });
                    } else if (id === 9) {
                        let a = ethers.utils.parseUnits(inputs.public_sale_price.toString(), 18);
                        tx = await presaleContract.setPublicRate(a, { from: address });
                    } else if (id === 10) {
                        tx = await presaleContract.setTreasuryWallet(inputs.treasurywallet, { from: address });
                    } else if (id === 11) {
                        tx = await presaleContract.setTokenAddress(inputs.ketatoken , inputs.ketatokendecimals, { from: address });
                    }
                    else {
                        return false;
                    }

                    const resolveAfter3Sec = new Promise((resolve) => setTimeout(resolve, 5000));
                    toast.promise(resolveAfter3Sec, {
                        pending: 'Waiting for confirmation',
                    });

                    var interval = setInterval(async function () {
                        let web3 = getWeb3(DEFAULT_CHAIN);
                        var response = await web3.eth.getTransactionReceipt(tx.hash);
                        if (response != null) {
                            clearInterval(interval);
                            if (response.status === true) {
                                toast.success('Success! Your last transaction is successful 👍');
                                setUpdater(Math.random());
                            } else if (response.status === false) {
                                toast.error('Error! Your last transaction failed.');
                                setUpdater(Math.random());
                            } else {
                                toast.error('Error! Something went wrong.');
                                setUpdater(Math.random());
                            }
                        }
                    }, 5000);
                } catch (error) {
                    console.log(error.message);
                    toast.error(error.reason ? error.reason : error.message);
                }
            } else {
                toast.error('Please select Binance Mainnet Network!');
            }
        } else {
            toast.error('Please Connect Wallet!');
        }
    };
    

    const handleStakeSubmit = async (id = 0) => {
        if (address && id) {
            if (chain && chain.id && parseInt(chain.id) === parseInt(DEFAULT_CHAIN)) {
                try {
                    let stakeContract = getContract(stakeABI , STAKE_ADDRESS , signer)
                    let tx;
                    if (id === 1) {
                        tx = await stakeContract.setNoRewardPeriod(inputs.no_reward_period, { from: address });
                    } else if (id === 2) {
                        let a = ethers.utils.parseUnits(inputs.gold_production.toString() , 100);
                        tx = await stakeContract.setParameters(a, inputs.gold_price, { from: address });
                    } else if (id === 3) {
                        tx = await stakeContract.setRewardInterval(inputs.reward_interval, { from: address });
                    } else if (id === 4) {
                        tx = await stakeContract.setStakingPeriod(inputs.staking_period, { from: address });
                    } else if (id === 5) {
                        const stakingStatus = inputs.staking_status.toLowerCase() === 'true';
                        tx = await stakeContract.toggleStaking(stakingStatus, { from: address });
                    } else if (id === 6) {
                        const claimStatus = inputs.claim_status.toLowerCase() === 'true';
                        tx = await stakeContract.toggleClaimStatus(claimStatus, { from: address });
                    } else if (id === 7) {
                        const withdrawStatus = inputs.withdraw_status.toLowerCase() === 'true';
                        tx = await stakeContract.toggleWithdrawStatus(withdrawStatus, { from: address });
                    } else if (id === 8) {
                        let amount = ethers.utils.parseUnits(inputs.token_amount.toString());
                        tx = await stakeContract.withdrawTokens(inputs.token_address, amount, { from: address });
                    } else {
                        return false;
                    }

                    const resolveAfter3Sec = new Promise((resolve) => setTimeout(resolve, 5000));
                    toast.promise(resolveAfter3Sec, {
                        pending: 'Waiting for confirmation',
                    });

                    var interval = setInterval(async function () {
                        let web3 = getWeb3(DEFAULT_CHAIN);
                        var response = await web3.eth.getTransactionReceipt(tx.hash);
                        if (response != null) {
                            clearInterval(interval);
                            if (response.status === true) {
                                toast.success('Success! Your last transaction is successful 👍');
                                setUpdater(Math.random());
                            } else if (response.status === false) {
                                toast.error('Error! Your last transaction failed.');
                                setUpdater(Math.random());
                            } else {
                                toast.error('Error! Something went wrong.');
                                setUpdater(Math.random());
                            }
                        }
                    }, 5000);
                } catch (error) {
                    console.log(error.message);
                    toast.error(error.reason ? error.reason : error.message);
                }
            } else {
                toast.error('Please select Binance Mainnet Network!');
            }
        } else {
            toast.error('Please Connect Wallet!');
        }
    }

    return (
        <>
            <div className="d-flex justify-content-between m-5">
                <Link to="/" class="logo">
                    <img src={logoImg} alt="logo" />
                </Link>
                <ConnectWallet className="connect-btn" />
            </div>
            <div className="mt-5">
                <h4 className='text-center py-3'>Presale Settings</h4>
                <table className="table table-hover table-dark">
                    <thead>
                        <tr>
                            <th scope="col">#</th>
                            <th scope="col">Name</th>
                            <th scope="col">Input</th>
                            <th scope="col">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <th scope="row">1</th>
                            <td>Rescue Token</td>
                            <td>
                                <input
                                    value={inputs.token_address}
                                    onChange={(e) => handleChange(e)}
                                    placeholder="Enter token address"
                                    type="text"
                                    name="token_address"
                                    className="form-control"
                                />
                            </td>
                            <td>
                                <button type="button" onClick={() => handleSubmit(1)} className="btn btn-success">
                                    Submit
                                </button>
                            </td>
                        </tr>
                        <tr>
                            <th scope="row">2</th>
                            <td>Set End Time(use https://www.epochconverter.com/)</td>
                            <td>
                                <input
                                    value={inputs.finish_timestamp}
                                    onChange={(e) => handleChange(e)}
                                    placeholder="Enter finish timestamp"
                                    type="text"
                                    name="finish_timestamp"
                                    className="form-control"
                                />
                            </td>
                            <td>
                                <button type="button" onClick={() => handleSubmit(2)} className="btn btn-success">
                                    Submit
                                </button>
                            </td>
                        </tr>
                        <tr>
                            <th scope="row">3</th>
                            <td>Set Min Purchase Limit</td>
                            <td>
                                <input
                                    value={inputs.min_purchase_limit}
                                    onChange={(e) => handleChange(e)}
                                    placeholder="Enter minimum limit"
                                    type="text"
                                    name="min_purchase_limit"
                                    className="form-control"
                                />
                            </td>
                            <td>
                                <button type="button" onClick={() => handleSubmit(3)} className="btn btn-success">
                                    Submit
                                </button>
                            </td>
                        </tr>
                        <tr>
                            <th scope="row">4</th>
                            <td>Set Token Private Sale Price(in dollar)</td>
                            <td>
                                <input
                                    value={inputs.private_sale_price}
                                    onChange={(e) => handleChange(e)}
                                    placeholder="Enter token sale price"
                                    type="text"
                                    name="private_sale_price"
                                    className="form-control"
                                />
                            </td>
                            <td>
                                <button type="button" onClick={() => handleSubmit(4)} className="btn btn-success">
                                    Submit
                                </button>
                            </td>
                        </tr>
                        <tr>
                            <th scope="row">5</th>
                            <td>Set Token Public Sale Price(in dollar)</td>
                            <td>
                                <input
                                    value={inputs.public_sale_price}
                                    onChange={(e) => handleChange(e)}
                                    placeholder="Enter token sale price"
                                    type="text"
                                    name="public_sale_price"
                                    className="form-control"
                                />
                            </td>
                            <td>
                                <button type="button" onClick={() => handleSubmit(9)} className="btn btn-success">
                                    Submit
                                </button>
                            </td>
                        </tr>
                        <tr>
                            <th scope="row">6</th>
                            <td>Set Sale Status</td>
                            <td>
                                <select
                                    onChange={(e) => handleChange(e)}
                                    name="sale_status"
                                    className="form-select"
                                    aria-label="Default select example"
                                >
                                    <option value="true" selected={inputs.sale_status === 'true'}>
                                        True
                                    </option>
                                    <option value="false" selected={inputs.sale_status === 'false'}>
                                        False
                                    </option>
                                </select>
                            </td>
                            <td>
                                <button type="button" onClick={() => handleSubmit(5)} className="btn btn-success">
                                    Submit
                                </button>
                            </td>
                        </tr>
                        <tr>
                            <th scope="row">7</th>
                            <td>Set Sale Type</td>
                            <td>
                                <select
                                    onChange={(e) => handleChange(e)}
                                    name="sale_type"
                                    className="form-select"
                                    aria-label="Default select example"
                                >
                                    <option value="0" selected={inputs.sale_type === '0'}>
                                        Private Sale
                                    </option>
                                    <option value="1" selected={inputs.sale_type === '1'}>
                                        Public Sale
                                    </option>
                                </select>
                            </td>
                            <td>
                                <button type="button" onClick={() => handleSubmit(6)} className="btn btn-success">
                                    Submit
                                </button>
                            </td>
                        </tr>
                        <tr>
                            <th scope="row">8</th>
                            <td>Withdraw All Sale Tokens</td>
                            <td></td>
                            <td>
                                <button type="button" onClick={() => handleSubmit(7)} className="btn btn-success">
                                    Submit
                                </button>
                            </td>
                        </tr>
                        <tr>
                            <th scope="row">9</th>
                            <td>Withdraw BNB</td>
                            <td></td>
                            <td>
                                <button type="button" onClick={() => handleSubmit(8)} className="btn btn-success">
                                    Submit
                                </button>
                            </td>
                        </tr>
                        <tr>
                            <th scope="row">10</th>
                            <td>Set TreasuryWallet</td>
                            <td>
                            <input
                                    value={inputs.treasurywallet}
                                    onChange={(e) => handleChange(e)}
                                    placeholder="Enter Treasurywallet"
                                    type="text"
                                    name="treasurywallet"
                                    className="form-control"
                                />
                            </td>
                            <td>
                                <button type="button" onClick={() => handleSubmit(10)} className="btn btn-success">
                                    Submit
                                </button>
                            </td>
                        </tr>
                        <tr>
                            <th scope="row">11</th>
                            <td>Set Token Address & Decimals</td>
                            <td>
                                <input
                                    value={inputs.ketatoken}
                                    onChange={(e) => handleChange(e)}
                                    placeholder="Enter ketatoken"
                                    type="text"
                                    name="ketatoken"
                                    className="form-control"
                                />
                                <input
                                    value={inputs.ketatokendecimals}
                                    onChange={(e) => handleChange(e)}
                                    placeholder="Enter ketatoken decimals"
                                    type="text"
                                    name="ketatokendecimals"
                                    className="form-control"
                                />
                            </td>
                            <td>
                                <button type="button" onClick={() => handleSubmit(11)} className="btn btn-success">
                                    Submit
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div className="mt-5">
                <h4 className='text-center py-3'>Stak Settings</h4>
                <table className="table table-hover table-dark">
                    <thead>
                        <tr>
                            <th scope="col">#</th>
                            <th scope="col">Name</th>
                            <th scope="col">Input</th>
                            <th scope="col">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <th scope="row">1</th>
                            <td>Set No Reward Period(in seconds)</td>
                            <td>
                                <input
                                    value={inputs.no_reward_period}
                                    onChange={(e) => handleChange(e)}
                                    placeholder="Enter time"
                                    type="text"
                                    name="no_reward_period"
                                    className="form-control"
                                />
                            </td>
                            <td>
                                <button type="button" onClick={() => handleStakeSubmit(1)} className="btn btn-success">
                                    Submit
                                </button>
                            </td>
                        </tr>
                        <tr>
                            <th scope="row">2</th>
                            <td>Set Parameters</td>
                            <td>
                                <input
                                    value={inputs.gold_production}
                                    onChange={(e) => handleChange(e)}
                                    placeholder="Enter gold production"
                                    type="text"
                                    name="gold_production"
                                    className="form-control my-1"
                                />
                                <input
                                    value={inputs.gold_price}
                                    onChange={(e) => handleChange(e)}
                                    placeholder="Enter gold price"
                                    type="text"
                                    name="gold_price"
                                    className="form-control my-1"
                                />
                            </td>
                            <td>
                                <button type="button" onClick={() => handleStakeSubmit(2)} className="btn btn-success">
                                    Submit
                                </button>
                            </td>
                        </tr>
                        <tr>
                            <th scope="row">3</th>
                            <td>Set Reward Interval(in seconds)</td>
                            <td>
                                <input
                                    value={inputs.reward_interval}
                                    onChange={(e) => handleChange(e)}
                                    placeholder="Enter time"
                                    type="text"
                                    name="reward_interval"
                                    className="form-control"
                                />
                            </td>
                            <td>
                                <button type="button" onClick={() => handleStakeSubmit(3)} className="btn btn-success">
                                    Submit
                                </button>
                            </td>
                        </tr>
                        <tr>
                            <th scope="row">4</th>
                            <td>Set Staking Period(in seconds)</td>
                            <td>
                                <input
                                    value={inputs.staking_period}
                                    onChange={(e) => handleChange(e)}
                                    placeholder="Enter time"
                                    type="text"
                                    name="staking_period"
                                    className="form-control"
                                />
                            </td>
                            <td>
                                <button type="button" onClick={() => handleStakeSubmit(4)} className="btn btn-success">
                                    Submit
                                </button>
                            </td>
                        </tr>
                        <tr>
                            <th scope="row">5</th>
                            <td>Toggle Staking</td>
                            <td>
                                <select
                                    onChange={(e) => handleChange(e)}
                                    name="staking_status"
                                    className="form-select"
                                    aria-label="Default select example"
                                >
                                    <option value="true" selected={inputs.staking_status === 'true'}>
                                        True
                                    </option>
                                    <option value="false" selected={inputs.staking_status === 'false'}>
                                        False
                                    </option>
                                </select>
                            </td>
                            <td>
                                <button type="button" onClick={() => handleStakeSubmit(5)} className="btn btn-success">
                                    Submit
                                </button>
                            </td>
                        </tr>
                        <tr>
                            <th scope="row">6</th>
                            <td>Toggle Claim Status</td>
                            <td>
                                <select
                                    onChange={(e) => handleChange(e)}
                                    name="claim_status"
                                    className="form-select"
                                    aria-label="Default select example"
                                >
                                    <option value="true" selected={inputs.claim_status === 'true'}>
                                        True
                                    </option>
                                    <option value="false" selected={inputs.claim_status === 'false'}>
                                        False
                                    </option>
                                </select>
                            </td>
                            <td>
                                <button type="button" onClick={() => handleStakeSubmit(6)} className="btn btn-success">
                                    Submit
                                </button>
                            </td>
                        </tr>
                        <tr>
                            <th scope="row">7</th>
                            <td>Toggle Withdraw Status</td>
                            <td>
                                <select
                                    onChange={(e) => handleChange(e)}
                                    name="withdraw_status"
                                    className="form-select"
                                    aria-label="Default select example"
                                >
                                    <option value="true" selected={inputs.withdraw_status === 'true'}>
                                        True
                                    </option>
                                    <option value="false" selected={inputs.withdraw_status === 'false'}>
                                        False
                                    </option>
                                </select>
                            </td>
                            <td>
                                <button type="button" onClick={() => handleStakeSubmit(7)} className="btn btn-success">
                                    Submit
                                </button>
                            </td>
                        </tr>
                        <tr>
                            <th scope="row">8</th>
                            <td>Withdraw Tokens</td>
                            <td>
                                <input
                                    value={inputs.stake_token_address}
                                    onChange={(e) => handleChange(e)}
                                    placeholder="Enter token address"
                                    type="text"
                                    name="stake_token_address"
                                    className="form-control my-1"
                                />
                                <input
                                    value={inputs.token_amount}
                                    onChange={(e) => handleChange(e)}
                                    placeholder="Enter token amount"
                                    type="text"
                                    name="token_amount"
                                    className="form-control my-1"
                                />
                                <input
                                    value={inputs.token_decimals}
                                    onChange={(e) => handleChange(e)}
                                    placeholder="Enter token decimal"
                                    type="text"
                                    name="token_decimals"
                                    className="form-control my-1"
                                />
                            </td>
                            <td>
                                <button type="button" onClick={() => handleStakeSubmit(8)} className="btn btn-success">
                                    Submit
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </>
    );
}
