import React from 'react'
import { HARD_CAP, SOFT_CAP, TOKEN_DECIMALS, TOKEN_NAME, TOKEN_SYMBOL, TOTAL_SUPPLY } from '../helper/constant'



export default function TokenomicsSection() {
    return (
        <section class="tokenomicsSection" id="tokenomics" data-aos="fade-up">
            <h2 class="tokenomicsHeader aos-init aos-animate" data-aos="fade-up">Tokenomics</h2>
            <div class="tokenomicsDetailsHolder">
                <div class="tokenomicsColumn aos-init aos-animate" data-aos="fade-up">
                    <h4 class="tokenDetailHead">Token Name</h4>
                    <p class="tokenDetailText">{TOKEN_NAME}</p>
                    <h4 class="tokenDetailHead">Total Symbol</h4>
                    <p class="tokenDetailText">{TOKEN_SYMBOL}</p>
                    <h4 class="tokenDetailHead">Decimals</h4>
                    <p class="tokenDetailText">{TOKEN_DECIMALS}</p>
                </div>
                <div class="tokenomicsColumn aos-init aos-animate" data-aos="fade-up">
                    <h4 class="tokenDetailHead">Total Supply</h4>
                    <p class="tokenDetailText">{TOTAL_SUPPLY} {TOKEN_SYMBOL}</p>
                    <h4 class="tokenDetailHead">Soft Cap</h4>
                    <p class="tokenDetailText">{SOFT_CAP}</p>
                    <h4 class="tokenDetailHead">Hard Cap</h4>
                    <p class="tokenDetailText">{HARD_CAP}</p>
                </div>
            </div>
        </section>

    )
}
