import React from 'react'

export default function HeroSection() {
    return (
        <>
            <section class="hero" data-aos="fade-up" id="home">
                <div class="hero-content">
                    <h1 class="hero-title">Invest in a Gold Mine Processing Facility: KETAGOLD (KETA) Tokens</h1>
                    <p class="hero-description">Invest in gold production. Earn reliable revenue, paid out in monthly dividend payments</p>
                    <p className='hero-desc text-white'>GESMINE-INVEST Ltd offers a unique investment opportunity in gold mining production in Tanzania through our KETAGOLD tokens. Stake your tokens and earn monthly dividends based on actual gold production from our East African processing plant.</p>
                </div>
            </section>
            <div className='row d-flex justify-content-center mt-2'>
                <div className='col-12 col-md-8'>
                    <h5 className='text-center'>
                        Want to invest in gold production but don't have crypto? <br/>No problem! Just <a href="#contact">contact us</a> for details.
                    </h5>
                </div>
            </div>
        </>
    )
}
