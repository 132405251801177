import React from 'react';
import Header from './Header';
import Footer from './Footer';
import ScrollToTop from './ScrollToTop';

const PrivacyPolicy = () => {
    return (
        <ScrollToTop>
            <div className='container'>
                <Header />
                <div className="terms-container">
                    <h1 className='text-center mb-5'>Privacy Policy</h1>

                    <h5>1. Information Collection</h5>
                    <p className="mb-3">We collect personal information such as name, email address, and wallet address when you register for the token presale.</p>

                    <h5>2. Use of Information</h5>
                    <p className="mb-3">We use collected information to process token purchases, provide customer support, and send updates about the project.</p>

                    <h5>3. KYC/AML Information</h5>
                    <p className="mb-3">Additional personal information may be collected for KYC/AML purposes. This information is securely stored and only used for compliance purposes.</p>

                    <h5>4. Information Sharing</h5>
                    <p className="mb-3">We do not sell your personal information. We may share information with service providers who assist in operating our platform or as required by law.</p>

                    <h5>5. Data Security</h5>
                    <p className="mb-3">We implement reasonable security measures to protect your personal information. However, no method of transmission over the internet is 100% secure.</p>

                    <h5>6. Cookies</h5>
                    <p className="mb-3">We use cookies to improve user experience. You can set your browser to refuse cookies, but this may limit your ability to use some features of our platform.</p>

                    <h5>7. Third-Party Links</h5>
                    <p className="mb-3">Our platform may contain links to third-party websites. We are not responsible for the privacy practices of these sites.</p>

                    <h5>8. Children's Privacy</h5>
                    <p className="mb-3">Our services are not intended for individuals under 18. We do not knowingly collect personal information from children.</p>

                    <h5>9. International Data Transfers</h5>
                    <p className="mb-3">Your information may be transferred to and processed in countries other than your own. By using our services, you consent to these transfers.</p>

                    <h5>10. Your Rights</h5>
                    <p className="mb-3">Depending on your jurisdiction, you may have rights to access, correct, or delete your personal information. Contact us to exercise these rights.</p>

                    <h5>11. Changes to Privacy Policy</h5>
                    <p className="mb-3">We may update this privacy policy from time to time. We will notify you of any significant changes.</p>

                    <h5>12. Contact Us</h5>
                    <p className="mb-3">If you have any questions about this privacy policy, please contact us at <a href="mailto:info@ketagold.com">info@ketagold.com</a>.</p>
                </div>
                <Footer />
            </div>
        </ScrollToTop>
    );
};

export default PrivacyPolicy;
