import React from 'react';
import logoImg from '../img/logo.gif';
import { Link } from 'react-router-dom';

export default function Footer() {
    return (
        <footer class="footer-section">
            <div class="container">
                <div class="footer-content pt-5 pb-5">
                    <div class="row d-flex justify-content-around">
                        <div class="col-xl-4 col-lg-4 mb-50">
                            <div class="footer-widget">
                                <div class="footer-logo">
                                    <a href="index.html">
                                        <img src={logoImg} class="img-fluid" alt="logo" />
                                    </a>
                                </div>
                                <div class="footer-text">
                                    <p>
                                        Discover gold mining investment opportunities in Tanzania with KETAGOLD. Our innovative approach allows you to invest in gold mine production in Africa through tokenization.
                                    </p>
                                </div>
                            </div>
                        </div>
                     
                        <div class="col-xl-4 col-lg-4 col-md-6 mb-30">
                            <div class="footer-widget">
                                <div class="footer-widget-heading">
                                    <h3>Useful Links</h3>
                                </div>
                                <div >
                                    <div><Link to="/privacy-policy" className='text-white'>Privacy Policy</Link></div>
                                    <div><Link to="/term-and-conditions" className='text-white'>Terms of Service</Link></div>
                                    <div><a target='_blank' rel="noreferrer" href="https://ketagold.com/info/" className='text-white'>Blog</a></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="copyright-area">
                <div class="container">

                    <div class="copyright-text text-center text-white">
                        <p>KETAGOLD by GESMINE-INVEST Ltd, UK Reg. Number: 14120136</p>
                    </div>

                </div>
            </div>
        </footer>

    )
}
