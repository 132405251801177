import React, { useEffect } from 'react'
import Header from '../components/Header'
import HeroSection from '../components/HeroSection'
import CopyAddressSection from '../components/CopyAddressSection'
import AboutSection from '../components/AboutSection'
import PresaleSection from '../components/PresaleSection'
import StakingSection from '../components/StakingSection'
import CalculatorSection from '../components/CalculatorSection'
import LuckydrawSection from '../components/LuckydrawSection'
import TokenomicsSection from '../components/TokenomicsSection'
import RoadmapSection from '../components/RoadmapSection'
import FaqSection from '../components/FaqSection'
import TeamSection from '../components/TeamSection'
import Imageslideshow from '../components/Imageslideshow'
import ContactSection from '../components/ContactSection'
import Footer from '../components/Footer'
import BusinessPlanSection from '../components/BusinessPlanSection'
import ScrollToTop from '../components/ScrollToTop'
import { useLocation } from 'react-router-dom'

export default function Home() {
    const location = useLocation();
    useEffect(() => {
        if (location.hash) {
          const element = document.querySelector(location.hash);
          if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
          }
        }
      }, [location]);
    return (
        <ScrollToTop>
            <div className='container'>
                <Header />
                <HeroSection />
                <PresaleSection />
                <CopyAddressSection />
                <AboutSection />
                <StakingSection />
                <CalculatorSection />
                <LuckydrawSection />
                <TokenomicsSection />
                <RoadmapSection />
                <FaqSection />
                <TeamSection />
                <Imageslideshow />
                <BusinessPlanSection />
                <ContactSection />
                <Footer />
            </div>
        </ScrollToTop>
    )
}
