import React from 'react'
import logoImg from '../img/logo.gif';

export default function AboutSection() {
    return (
        <section class="aboutSection" id="about" data-aos="fade-up">
            <div class="aboutTextSection aos-init aos-animate" data-aos="fade-up">
                <h3 class="aboutHeader" data-aos="fade-up">About KETAGOLD: Facilitating Investments in Gold Mining Production in East Africa</h3>
                <div class="aboutText" data-aos="fade-up">
                    <i className="fa-solid fa-location-arrow"></i>GESMINE-INVEST is revolutionizing gold mining investment through blockchain technology. Our KETAGOLD project aims to construct a gold processing plant near large-scale gold mines in the Lupa goldfields of Tanzania, with the goal of producing between 4.5 kg and 6.5 kg of gold per month.
                </div>
                <div class="aboutText" data-aos="fade-up">
                    <i className="fa-solid fa-location-arrow"></i>With over 10 years of experience in East African mining, our team provides the expertise needed to maximize returns for our investors. By tokenizing gold processing ownership, we offer unprecedented access to gold mining profits.
                </div>
                <div class="aboutText" data-aos="fade-up">
                    <i className="fa-solid fa-location-arrow"></i>Looking to buy a gold mine in Africa? GESMINE-INVEST provides a better and simplified investment process through tokenization. Own a stake in our Tanzania gold processing plant and join the lucrative East African mining sector.
                </div>
            </div>
            <div class="aboutImageSection aos-init aos-animate" data-aos="fade-up">
                <img src={logoImg} alt="image" />
            </div>
        </section>


    )
}
