import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ethereumClient } from "./utils/wagmi";
import { Web3Modal } from "@web3modal/react";
import { chains, PROJECT_ID } from './helper/constant';
import { WagmiConfig } from "wagmi";
import { wagmiClient } from "./utils/wagmi";


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
     <WagmiConfig client={wagmiClient}>
      <ToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <App />
      <Web3Modal
        projectId={PROJECT_ID}
        ethereumClient={ethereumClient}
        defaultChain={chains[0]}
        themeVariables={{
          '--w3m-accent-color': '#fd961a',
          '--w3m-background-color': '#fd961a',
        }}
      />
    </WagmiConfig>
  </React.StrictMode>
);