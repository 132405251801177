import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import Home from "./pages/Home";
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useEffect } from "react";
import TermsAndConditions from "./components/TermsAndConditions";
import PrivacyPolicy from "./components/PrivacyPolicy";
import Admin from "./pages/Admin";

function App() {
  useEffect(() => {
    AOS.init({
      duration: 1000, // duration of the animation in milliseconds
    });
  }, []);
  return (
    <div className="App">
        <BrowserRouter>
          <Routes>
            <Route exact path="/" element={<Home />} />
            <Route exact path="/term-and-conditions" element={<TermsAndConditions />} />
            <Route exact path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route exact path="/admin" element={<Admin />} />
          </Routes>
        </BrowserRouter>
    </div>
  );
}

export default App;
