import React from 'react';
import logoImg from '../img/logo.gif';
import ConnectWallet from './ConnectWallet';
import { Link } from 'react-router-dom';

const menuItems = [
    { islink : true , href: "/#token-sale", text: "Token Sale", icon: "fa-solid fa-tags" },
    { islink : true , href: "/#about", text: "About", icon: "fa-solid fa-circle-info" },
    { islink : true , href: "/#staking", text: "Staking", icon: "fa-solid fa-coins" },
    { islink : true , href: "/#profit-calculator", text: "Profit Calculator", icon: "fa-solid fa-calculator" },
    { islink : true , href: "/#lucky-draw", text: "Raffle", icon: "fa-solid fa-trophy" },
    { islink : true , href: "/#tokenomics", text: "Tokenomics", icon: "fa-solid fa-chart-pie" },
    { islink : true , href: "/#roadmap", text: "Roadmap", icon: "fa-solid fa-road" },
    { islink : true , href: "/#faq", text: "FAQ", icon: "fa-solid fa-question-circle" },
    { islink : true , href: "/#team", text: "Team", icon: "fa-solid fa-question-circle" },
    { islink : true , href: "/#whitepaper", text: "Whitepaper", icon: "fa-solid fa-question-circle" },
    { islink : true , href: "/#contact", text: "Contact", icon: "fa-solid fa-question-circle" }
];

export default function Header() {
    return (
        <>
            <div class="progress-wrap">
                <svg class="progress-circle svg-content" width="100%" height="100%" viewBox="-1 -1 102 102">
                    <path d="M50,1 a49,49 0 0,1 0,98 a49,49 0 0,1 0,-98" />
                </svg>
            </div>
            <header>
                <div className='d-flex'>
                    <button class="navbar-toggler d-md-none" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasExample" aria-controls="offcanvasExample">
                        <i class="fa-2x fa-solid fa-bars"></i>
                    </button>
                    <Link to="/" class="logo">
                        <img src={logoImg} alt="logo" />
                    </Link>
                </div>

                <ul class="navbarr" >
                    {menuItems.map((item, index) => (
                        <li>
                            {item.islink ? (
                                <Link to={item.href} class="active">{item.text}</Link>
                            ) : (
                                <a href={item.href} class="active">{item.text}</a>
                            )}
                            
                        </li>
                    ))}
                </ul>

                <div class="mainn">
                    <ConnectWallet className="connect-btn" />
                </div>
            </header>
            <div class="offcanvas offcanvas-start" tabindex="-1" id="offcanvasExample" aria-labelledby="offcanvasExampleLabel" aria-hidden="true">
                <div class="offcanvas-header">
                    <a class="navbar-brand my-3" href="#sec">
                        <img src={logoImg} height="65px" alt="logo-mobile" />
                    </a>
                    <button type="button" class="text-reset btn-close" data-bs-dismiss="offcanvas" aria-label="Close">
                        <svg viewBox="0 0 24 24" width="24" height="24" stroke="#fff" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1"><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg>
                    </button>
                </div>
                <div class="offcanvas-body">
                    <ul class="navbar-nav ms-auto mb-2 mb-lg-0">
                        {menuItems.map((item, index) => (
                            <li className='nav-item'>
                                <a href={item.href} class="nav-link p-0 text-white">{item.text}</a>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        </>


    );
}
