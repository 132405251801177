import { getMultiCall, getWeb3Contract } from '../helper/contractHelper';
import { BUY_CURRENCY, PRESALE_ADDRESS, STAKE_ADDRESS, TOKEN_ADDRESS, TOKEN_DECIMALS } from '../helper/constant';
import { useEffect, useState } from 'react';
import presaleAbi from '../abis/presale.json';
import tokenAbi from '../abis/token.json';
import stakeAbi from '../abis/stake.json';
import { toast } from 'react-toastify';
import { useAccount } from 'wagmi';
import { getWeb3 } from '../helper/functions';



export const useAccountStats = (updator) => {
    const { address } = useAccount();
    const web3 = getWeb3()
    const [stats, setStats] = useState({
        bnbBal: 0,
        tokenBal: 0,
        isApproved: 0,
        purchasedOf: 0
    });

    let presaleContract = getWeb3Contract(presaleAbi, PRESALE_ADDRESS);
    let tokenContract = getWeb3Contract(tokenAbi, BUY_CURRENCY[0].address);


    useEffect(() => {
        const fetch = async () => {
            try {
                const data = await getMultiCall([
                    presaleContract.methods.purchasedOf(address),
                    tokenContract.methods.balanceOf(address),
                    tokenContract.methods.allowance(address, PRESALE_ADDRESS),
                ])

                let bnbBal = await web3.utils.fromWei(await web3.eth.getBalance(address), 'ether')



                setStats({
                    bnbBal,
                    tokenBal: parseFloat(data[1]) / Math.pow(10, BUY_CURRENCY[0].decimals),
                    isApproved: parseFloat(data[2]) / Math.pow(10, BUY_CURRENCY[0].decimals),
                    purchasedOf: data[0] / Math.pow(10, TOKEN_DECIMALS),
                })
            }
            catch (err) {
                console.log(err)
                toast.error(err.reason ? err.reason : err.message);
            }
        }

        if (address) {
            fetch()
        }
        else {
            setStats({
                bnbBal: 0,
                tokenBal: 0,
                isApproved: 0,
                purchasedOf: 0
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [updator, address])

    return stats;
}


export const useStakeStats = (updator) => {
    const { address } = useAccount();
    const [stats, setStats] = useState({
        tokenBal: 0,
        isApproved: 0,
        purchasedOf: 0,
        pendingDividend: 0,
        totalRewardEarn: 0,
        unlocktime: 0,
        stakestatus: false,
        isClaimed : false
    });

    let presaleContract = getWeb3Contract(stakeAbi, STAKE_ADDRESS);
    let tokenContract = getWeb3Contract(tokenAbi, TOKEN_ADDRESS);


    useEffect(() => {
        const fetch = async () => {
            try {
                const data = await getMultiCall([
                    presaleContract.methods.balanceOf(address), //0
                    tokenContract.methods.balanceOf(address), //1
                    tokenContract.methods.allowance(address, STAKE_ADDRESS), //2
                    presaleContract.methods.getUserPendingReward(address), //3
                    presaleContract.methods.totalRewardEarn(address), //4
                    presaleContract.methods.userOrderId(address), //5
                ])
                let unlocktime = 0;
                let isClaimed = false;
                if (data[5] > 0) {
                    let orderInfo = await presaleContract.methods.orders(data[5]).call();
                    unlocktime = orderInfo[3];
                    isClaimed = orderInfo[7];
                }

                

                setStats({
                    tokenBal: parseFloat(data[1]) / Math.pow(10, TOKEN_DECIMALS),
                    isApproved: parseFloat(data[2]) / Math.pow(10, TOKEN_DECIMALS),
                    purchasedOf: data[0] / Math.pow(10, TOKEN_DECIMALS),
                    pendingDividend: data[3][0] / Math.pow(10, 18),
                    totalRewardEarn: data[4] / Math.pow(10, 18),
                    unlocktime,
                    isClaimed
                })
            }
            catch (err) {
                console.log(err)
                toast.error(err.reason ? err.reason : err.message);
            }
        }

        if (address) {
            fetch()
        }
        else {
            setStats({
                tokenBal: 0,
                isApproved: 0,
                purchasedOf: 0,
                pendingDividend: 0,
                totalRewardEarn: 0,
                unlocktime: 0,
                isClaimed : false
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [updator, address])

    return stats;
}

export const useStakeCommonStats = (updator) => {
    const [stats, setStats] = useState({
        stakestatus: false,
        goldproduction : 0,
        NO_REWARD_PERIOD : 0,
        STAKING_PERIOD : 0
    });

    let presaleContract = getWeb3Contract(stakeAbi, STAKE_ADDRESS);



    useEffect(() => {
        const fetch = async () => {
            try {
                const data = await getMultiCall([
                    presaleContract.methods.started(),
                    presaleContract.methods.goldproduction(),
                    presaleContract.methods.NO_REWARD_PERIOD(),
                    presaleContract.methods.STAKING_PERIOD(),
                ])

                


                setStats({
                    stakestatus: data[0],
                    goldproduction : data[1],
                    NO_REWARD_PERIOD : data[2],
                    STAKING_PERIOD : data[3]
                })
            }
            catch (err) {
                console.log(err)
                toast.error(err.reason ? err.reason : err.message);
            }
        }


        fetch()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [updator])

    return stats;
}

