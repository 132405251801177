import React from 'react';
import Header from './Header';
import Footer from './Footer';
import ScrollToTop from './ScrollToTop';

const TermsAndConditions = () => {
    return (
        <ScrollToTop>
            <div className='container'>
                <Header />
                <div className="terms-container">
                    <h1 className='text-center mb-5'>Terms of Service</h1>

                    <h5>1. Acceptance of Terms</h5>
                    <p className='mb-3'>By accessing and participating in the KETAGOLD (KETA) token presale, you agree to be bound by these Terms of Service.</p>

                    <h5>2. Eligibility</h5>
                    <p className='mb-3'>You must be at least 18 years old and legally able to participate in cryptocurrency transactions in your jurisdiction to participate in the KETA token presale.</p>

                    <h5>3. Token Purchase</h5>
                    <p className='mb-3'>KETA tokens are sold on an "as is" basis. GESMINE-INVEST Ltd makes no warranties regarding the tokens' value or future performance.</p>

                    <h5>4. Risks</h5>
                    <p className='mb-3'>Participation in the token presale involves significant risk. You acknowledge and accept the risks associated with purchasing and holding cryptocurrency tokens.</p>

                    <h5>5. Staking and Dividends</h5>
                    <p className='mb-3'>
                        Dividend payments are subject to the performance of the gold mine and may vary. GESMINE-INVEST Ltd reserves the right to modify the staking and dividend structure as needed.
                        We maintain the right to close the staking interface after 2 years. Users who have not staked their tokens one year after the soft cap has been reached risk losing their investment.
                        To protect your investment, we strongly recommend staking as soon as possible after the soft cap has been reached. Additionally, tokens that remain in staking due to late staking will
                        forfeit their remaining monthly dividends. Therefore, it's important to stake your tokens early to avoid losing any potential dividends.
                    </p>
                    <h5>Staking Process:</h5>
                    <p>1. Staking Opening: Staking will be open for 3 months following its activation.</p>
                    <p>2. Staking Limit: Users can stake only once.</p>
                    <p>3. Staking Period: The staking period is 474 days, comprising 90 days with no reward and 384 days with USDT rewards.</p>
                    <p>4. Reward Claim: Users can claim USDT rewards every 32 days.</p>
                    <p>5. Monthly revenue for one token (in USDT) = (M * P * 0.5) / 1,000,000,000.</p>
                    <p>6. Withdrawal: After the 474-day staking period, users can withdraw their tokens.</p>
                    <p className='mb-3'>7. Reward Termination: No rewards will be distributed after the 474-day period.</p>

                    <h5>6. Refund Policy</h5>
                    <p className='mb-3'>Tokens purchased cannot be returned or refunded, except under particular conditions or as mandated by relevant laws.</p>

                    <h5>7. Intellectual Property</h5>
                    <p className='mb-3'>All intellectual property rights related to KETAGOLD and the KETA token remain the property of GESMINE-INVEST Ltd.</p>

                    <h5>8. Limitation of Liability</h5>
                    <p className='mb-3'>GESMINE-INVEST Ltd shall not be liable for any indirect, incidental, special, consequential, or punitive damages.</p>

                    <h5>9. Governing Law</h5>
                    <p className='mb-3'>These terms are governed by the laws of the UK, without regard to its conflict of law principles.</p>

                    <h5>10. Changes to Terms</h5>
                    <p className='mb-3'>GESMINE-INVEST Ltd reserves the right to modify these terms at any time. Continued use of the service after changes constitutes acceptance of the new terms.</p>

                </div>
                <Footer />
            </div>
        </ScrollToTop>

    );
};

export default TermsAndConditions;
